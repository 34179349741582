<template>
    <div
        class="pa-12"
    >
        <v-btn
            @click="insertDB(ability_list)"
            large
            depressed
            dark
            color="primary"
            class="px-12 rounded-10"
        >
            입력
        </v-btn>

        {{ability_list}}
    </div>

    
</template>
<script>
export default {
    data: () => ({
        ability_list: [],
    }),

    mounted(){
        this.fetchAbilities()
    },

    methods: {
        async fetchAbilities() {
            const response = await fetch('https://pokeapi.co/api/v2/ability?limit=500')
            const data = await response.json()
            
            // 각 능력치의 상세 정보 가져오기
            const detailPromises = data.results.map(ability => 
                fetch(ability.url).then(res => res.json())
            )
            
            const abilityDetails = await Promise.all(detailPromises)
            
            // 데이터 변환
            const transformedData = abilityDetails.map(ability => {
                // 한국어 이름 찾기
                const koreanName = ability.names.find(name => 
                    name.language.name === 'ko'
                )
                
                // 한국어 설명 찾기
                const koreanFlavor = ability.flavor_text_entries.find(entry => 
                    entry.language.name === 'ko'
                )
                
                return {
                    name_en: ability.name,
                    name_kr: koreanName ? koreanName.name : null,
                    text_kr: koreanFlavor ? koreanFlavor.flavor_text : null
                }
            })

            console.log('변환된 데이터:', transformedData)
            this.ability_list = transformedData
        },

        insertDB(abilityList) {
            abilityList.forEach(item => {
                this.$http.post("/api/ability/insert", {
                    params: {
                        en: item.name_en,
                        kr: item.name_kr,
                        text: item.text_kr
                    }
                }).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.error(err)
                })
            })
        }
    }
}
</script>