<template>
    <v-sheet
        :class="!$vuetify.breakpoint.mobile? 'rounded-12':''"
        :style="!$vuetify.breakpoint.mobile?
            {
                width: '362px',
                minHeight: '841px',
                border: '1px solid #ddd',
                paddingBottom: '10px',
                boxShadow: '0 0 6px 0 #e1e1e1'
            }
            : 
            {
                height: '100%'
            }
        "
    >
        <!-- # 1줄 -->
        <div
            class="d-flex align-center"
            style="height:64px;"
        >
            <!-- # 포켓몬 이름 -->
            <v-autocomplete
                v-if="$vuetify.breakpoint.mobile"
                ref="pokemonAutocomplete"
                class="rounded-lg shrink ml-2"
                style="width:195px;"
                placeholder="포켓몬 선택"
                persistent-placeholder
                dense
                hide-details
                item-value="en"
                item-text="kr"
                :items="pokemon_list"
                v-model="pokemon.name"
                outlined
                :menu-props="{
                    offsetY: true,
                    maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                }"
                @change="loadPokemon()"
                @keydown.enter.native="handleEnter"
            ></v-autocomplete>

            <v-spacer></v-spacer>

            <!-- 검 -->
            <div
                class="ml-2 mr-3"
                style="width:24px; height:24px;"
                @click="$vuetify.breakpoint.mobile? toggleMobileTab2() :''"
            >
                <v-img
                    src="@/assets/icons/attacker.png"
                ></v-img>
            </div>

            <!-- 스피드 -->
            <div
                class="mr-1 mt-2px"
                style="width:14px; height:24px;"
                @click="$vuetify.breakpoint.mobile? toggleMobileTab() : $store.commit('update_is_speed', true)"
            >
                <v-img
                    src="@/assets/icons/speed_off.png"
                ></v-img>
            </div>

            <!-- 도감 -->
            <div
                class="ml-2 mr-4"
                style="width:25px; height:24px; margin-top:3px;"
                @click="$vuetify.breakpoint.mobile? toggleMobileTab3() : $store.commit('update_is_docs', !$store.state.is_docs)"
            >
                <v-img
                    contain
                    width="25"
                    src="@/assets/icons/technic.png"
                ></v-img>
            </div>
        </div>

        <div
            style="padding:0 10px;"
        >
            <div
                class="rounded-lg"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; padding-bottom:2px;"
                    class="rounded-lg white--text text-center d-flex align-center justify-center"
                >
                    1세대
                    <v-icon color="white" size="16">mdi-menu-down</v-icon>
                </div>
            </div>
        </div>

        <div
            style="padding:0 10px; margin-top:10px;"
        >
            <div
                class="rounded-lg"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; padding-bottom:2px;"
                    class="rounded-t-lg white--text text-center d-flex align-center justify-center"
                >
                    레벨업으로 배우는 기술
                    <v-icon color="white" size="16">mdi-menu-down</v-icon>
                </div>
                <table
                    class="docs_table"
                >
                    <tr>
                        <th>레벨</th>
                        <th>기술명</th>
                        <th>타입</th>
                        <th>분류</th>
                        <th>위력</th>
                        <th>명중률</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>

        <div
            style="padding:0 10px; margin-top:10px;"
        >
            <div
                class="rounded-lg"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; padding-bottom:2px;"
                    class="rounded-t-lg white--text text-center d-flex align-center justify-center"
                >
                    머신으로 배우는 기술
                    <v-icon color="white" size="16">mdi-menu-down</v-icon>
                </div>
                <table
                    class="docs_table"
                >
                    <tr>
                        <th>레벨</th>
                        <th>기술명</th>
                        <th>타입</th>
                        <th>분류</th>
                        <th>위력</th>
                        <th>명중률</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>

        <div
            style="padding:0 10px; margin-top:10px;"
        >
            <div
                class="rounded-lg"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; padding-bottom:2px;"
                    class="rounded-t-lg white--text text-center d-flex align-center justify-center"
                >
                    교배로 배우는 기술
                    <v-icon color="white" size="16">mdi-menu-down</v-icon>
                </div>
                <table
                    class="docs_table"
                >
                    <tr>
                        <th>레벨</th>
                        <th>기술명</th>
                        <th>타입</th>
                        <th>분류</th>
                        <th>위력</th>
                        <th>명중률</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>

        <div
            style="padding:0 10px; margin-top:10px;"
        >
            <div
                class="rounded-lg"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; padding-bottom:2px;"
                    class="rounded-t-lg white--text text-center d-flex align-center justify-center"
                >
                    가르침으로 배우는 기술
                    <v-icon color="white" size="16">mdi-menu-down</v-icon>
                </div>
                <table
                    class="docs_table"
                >
                    <tr>
                        <th>레벨</th>
                        <th>기술명</th>
                        <th>타입</th>
                        <th>분류</th>
                        <th>위력</th>
                        <th>명중률</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>

        <div
            style="padding:0 10px; margin-top:10px;"
        >
            <div
                class="rounded-lg"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; padding-bottom:2px;"
                    class="rounded-t-lg white--text text-center d-flex align-center justify-center"
                >
                    이벤트로 배우는 기술
                    <v-icon color="white" size="16">mdi-menu-down</v-icon>
                </div>
                <table
                    class="docs_table"
                >
                    <tr>
                        <th>레벨</th>
                        <th>기술명</th>
                        <th>타입</th>
                        <th>분류</th>
                        <th>위력</th>
                        <th>명중률</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
    </v-sheet>
</template>
<script>
import common from '@/data/common.json'

export default {
    data: () => ({
        // 타입 목록
        type_list: common.type_list,

        // 포켓몬 목록
        pokemon_list: [],

        pokemon: {
            abilities: [],

            name: "",
            name_kr: "",
            name_jp: "",
            sprite_default: "",
            sprite_shiny: "",

            types: [],

            egg_groups: [],
            hatch_counter: 0,
            base_happiness: 0,
            total_experience: 0,
            color: "",

            height: 0,
            weight: 0,

            // 스탯
            stats: {
                // H
                hp: 0,

                // A
                attack: 0,

                // B
                defense: 0,

                // C
                special_attack: 0,

                // D
                special_defense: 0,

                // S
                speed: 0,
            },

            genera_type: "",
            capture_rate: 0,

            genetics: {
                female: 0,
                male: 0,
            },

            docs_explain: {},
        },

        type_counter_list: {
            노말: 1,
            불꽃: 1,
            물: 1,
            풀: 1,
            전기: 1,
            얼음: 1,
            격투: 1,
            독: 1,
            땅: 1,
            비행: 1,
            에스퍼: 1,
            벌레: 1,
            바위: 1,
            고스트: 1,
            드래곤: 1,
            악: 1,
            강철: 1,
            페어리: 1,
        },

        chain_list: [],

        loading: false,
        componentKey: 0,

        selected_ability: {},

        dialog: {
            ability: false,
        },

        name_addon: [
            "-galar",
            "-alola",
            "-hisui"
        ],

        group: {
            basic: true,
            stat: true,
            evolution: false,
            docs_explain: false,
            genetics: false,
        }
    }),

    mounted(){
        // 포켓몬 목록 불러오기
        this.loadPokemonList()
    },

    methods: {
        handleEnter(e) {
            const currentValue = e.target.value
            if (currentValue) {
                // 한글 이름으로 영어 이름 찾기
                const pokemon = this.pokemon_list.find(p => p.kr === currentValue)
                if (pokemon) {
                    this.pokemon.name = pokemon.en // 영어 이름으로 설정
                    this.loadPokemon()
                    this.$refs.pokemonAutocomplete.blur()
                } else {
                    // 매칭되는 포켓몬이 없을 경우 직접 입력값 사용
                    this.pokemon.name = currentValue 
                    this.loadPokemon()
                    this.$refs.pokemonAutocomplete.blur()
                }
            }
        },

        // 포켓몬 목록 불러오기
        loadPokemonList() {
            this.$http.post("/api/pokemon/select")
            .then((res) => {
                this.pokemon_list = res.data
            })
        },

        toggleMobileTab3() {
            if (this.$store.state.mobile_tab === 'docs_left') {
                this.$store.commit('update_mobile_tab', 'docs_right')
            } else {
                this.$store.commit('update_mobile_tab', 'docs_left')
            }
        },

        toggleMobileTab2() {
            if (this.$store.state.mobile_tab === 'speed_defender') {
                this.$store.commit('update_mobile_tab', 'speed_attacker')
            } else {
                this.$store.commit('update_mobile_tab', 'attacker')
            }
        },

        toggleMobileTab() {
            if (this.$store.state.mobile_tab === 'speed_defender') {
                this.$store.commit('update_mobile_tab', 'defender')
            } else {
                this.$store.commit('update_mobile_tab', 'speed_defender')
            }
        },

        // 포켓몬(pokemon) 선택 불러오기
        async loadPokemon(){
            this.loading = true
            await this.$http.get("https://pokeapi.co/api/v2/pokemon/"+this.pokemon.name)
            .then(async(res) => {
                console.log("pokemon", res.data)

                return

                this.$http.post("/api/docs_explain/select/specific", {
                    params: {
                        pokemon_name: this.pokemon.name
                    }
                }).then(async(res) => {
                    console.log("docs_explain", res.data[0])
                    this.pokemon.docs_explain = res.data[0]
                })


                // 포켓몬 이미지
                this.pokemon.sprite_default = res.data.sprites.front_default
                this.pokemon.sprite_shiny = res.data.sprites.front_shiny


                this.pokemon.height = (res.data.height * 1/10).toFixed(1)
                this.pokemon.weight = (res.data.weight * 1/10).toFixed(1)


                const foundPokemon = this.pokemon_list.find(e => e.en === this.pokemon.name)
                if (foundPokemon) {
                    this.pokemon.name_kr = foundPokemon.kr
                    this.pokemon.name_jp = foundPokemon.jp
                }

                // 포켓몬 타입
                // this.pokemon.types = []
                // await res.data.types.forEach(async(type) => {
                //     await this.$http.get(type.type.url)
                //     .then(async(res) => {
                //         await this.pokemon.types.push(res.data.names[1].name)
                //     })
                // })


                // await this.caculate_type_counter()
                
                // 포켓몬 타입
                this.pokemon.types = []
                const typePromises = res.data.types.map(type => 
                    this.$http.get(type.type.url)
                    .then(res => {
                        this.pokemon.types.push(res.data.names[1].name)
                    })
                )

                await Promise.all(typePromises)
                await this.caculate_type_counter()

                // 포켓몬 스탯
                this.pokemon.stats.hp = res.data.stats[0].base_stat
                this.pokemon.stats.attack = res.data.stats[1].base_stat
                this.pokemon.stats.defense = res.data.stats[2].base_stat
                this.pokemon.stats.special_attack = res.data.stats[3].base_stat
                this.pokemon.stats.special_defense = res.data.stats[4].base_stat
                this.pokemon.stats.speed = res.data.stats[5].base_stat

                // abilities 배열의 각 URL을 비동기적으로 처리
                Promise.all(res.data.abilities.map(e => this.$http.get(e.ability.url)))
                .then(responses => {
                    console.log("ability", responses)
                    // slot 번호와 특성 이름, 설명을 함께 저장할 배열 생성
                    const abilitiesWithSlot = responses.map((response, index) => {
                        const nameObj = response.data.names.find(name => name.language.name === "ko")
                        // 한국어 설명 찾기
                        const flavorText = response.data.flavor_text_entries
                            .filter(entry => entry.language.name === "ko")
                            .pop()?.flavor_text || null

                        return {
                            slot: res.data.abilities[index].slot,  // 원본 데이터의 slot 번호
                            name: nameObj ? nameObj.name : null,
                            text: flavorText
                        }
                    })

                    // slot 번호로 정렬된 특성 이름과 설명만 추출
                    this.pokemon.abilities = new Array(3).fill(null)  // 3개의 null로 초기화
                    abilitiesWithSlot.forEach(ability => {
                        this.pokemon.abilities[ability.slot - 1] = {
                            name: ability.name,
                            text: ability.text
                        }
                    })
                })

                await this.$http.get(res.data.species.url)
                .then(async(res) => {
                    console.log("species", res.data)

                    this.pokemon.pokedex_list = await this.formatPokedexNumbers(res.data.pokedex_numbers)

                    this.pokemon.genera_type = res.data.genera[1].genus
                    this.pokemon.hatch_counter = res.data.hatch_counter * 256
                    this.pokemon.base_happiness = res.data.base_happiness

                    // egg_groups 배열의 각 URL을 비동기적으로 처리
                    Promise.all(res.data.egg_groups.map(group => this.$http.get(group.url)))
                    .then(responses => {
                        this.pokemon.egg_groups = responses.map(response => {
                            const nameObj = response.data.names.find(name => name.language.name === "ko")
                            return nameObj ? nameObj.name : null
                        })
                    })

                    this.$http.get(res.data.growth_rate.url)
                    .then((res) => {
                        this.pokemon.total_experience = res.data.levels[99].experience
                    })

                    this.$http.get(res.data.color.url)
                    .then((res) => {
                        const nameObj = res.data.names.find(name => name.language.name === "ko")
                        this.pokemon.color = nameObj ? nameObj.name : null
                    })

                    this.pokemon.capture_rate = res.data.capture_rate
                    this.pokemon.genetics.female = res.data.gender_rate * 12.5
                    this.pokemon.genetics.male = 100 - (res.data.gender_rate * 12.5)

                    // # 진화 과정
                    this.$http.get(res.data.evolution_chain.url)
                    .then(async(res) => {
                        let chain = res.data.chain
                        console.log("진화 체인", await this.getEvolutionChain(chain))
                        this.chain_list = await this.getEvolutionChain(chain)
                    })


                    this.loading = false
                })
            })
        },
    }
}
</script>
<style scoped>
.docs_table {
    width: calc(100% - 2px);
    margin:0 1px;
    height:100%;
    border-collapse: collapse;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #d9d9d9;
}

::v-deep .docs_table th {
    height: 25px;
    font-weight: 500;
    padding-bottom: 2px;
    background:#D9D9D9 !important;
    font-size: 10px;
}

::v-deep .docs_table td {
    border-right: 1px solid #d9d9d9;
    padding: 4px;
    font-size: 10px;
    font-weight: 400;
}

::v-deep .docs_table td:first-child {
    width: 120px;
}

::v-deep .docs_table td:last-child {
    border-right: none;
}

.docs_table2 {
    width: calc(100% - 2px);
    margin:0 1px;
    height:100%;
    border-collapse: collapse;
    border-radius: 8px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #d9d9d9;
}

::v-deep .docs_table2 th {
    height: 25px;
    font-weight: 500;
    padding-bottom: 2px;
    background:#D9D9D9 !important;
    font-size: 10px;
}

::v-deep .docs_table2 td {
    border-right: 1px solid #d9d9d9;
    padding: 4px;
    height: 23px;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
}

::v-deep .docs_table2 td:first-child {
    width: 120px;
}

::v-deep .docs_table2 td:last-child {
    border-right: none;
}
</style>